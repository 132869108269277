<template>
  <div
    class="page-not-found"
    :style="{ backgroundImage: `url(${item.backgroundImage.filename})` }"
  >
    <div class="page-not-found__content">
      <h1
        class="text-xl uppercase sm:text-above-h2 tracking-lg mb-9.5"
        :style="{ 'mask-image': `url(${item.titleMask.filename})` }"
      >
        {{ $t(item.title) }}
      </h1>
      <p class="text-above-md my-9">
        {{ $t(item.subtitle) }}
      </p>
      <div class="page-not-found__actions">
        <button-full
          class="mt-4 sm:mt-0 pr-5 pl-10"
          @click.native="goTo(item.buttonLink.cached_url)"
        >
          <span>
            {{ $t(item.buttonTitle) }}
          </span>
          <i class="ss-gizmo checkout-icon ss-right text-h5" />
        </button-full>
      </div>
    </div>
  </div>
</template>

<script>
import { Blok } from 'src/modules/vsf-storyblok-module/components'
import ButtonFull from 'theme/components/theme/ButtonFull'

export default {
  name: 'PageNotFound',
  extends: Blok,
  components: {
    ButtonFull
  },
  methods: {
    goTo (link) {
      this.$router.push(this.localizedRoute(link))
    }
  }
}
</script>

<style lang="scss" scoped>
.page-not-found {
  @apply relative flex items-center bg-cover bg-center;
  min-height: calc(100vh - 119px);

  @screen lg {
    min-height: calc(100vh - 245px);
  }

  @screen xl {
    min-height: calc(100vh - 295px);
  }

  &__content {
    @apply relative text-white text-center mx-auto px-md py-10 z-3;

    @screen sm {
      @apply pt-30 pb-20;
    }
  }

  &__actions {
    @apply pt-13;

    &::v-deep {
      button {
        @apply border border-primary2 text-primary2 w-full mx-auto;
        max-width: 215px;

        &:hover,
        &:focus {
          @apply bg-white text-black;
        }

        & > span {
          @apply flex justify-between items-center w-full;
        }
      }
    }
  }
}
</style>
